<template>
  <div>
    <div
      class="d-flex flex-wrap align-items-center pb-2 border-bottom mb-4 mt-4"
      style="min-height: 50px"
    >
      <h3 v-text="angket ? angket.nama : '-'"></h3>
    </div>
    <div class="container">
      <div class="row">
        <div
          class="col-lg-12 mt-3"
          v-for="(form, index) in angket.pertanyaan"
          :key="index"
        >
          <h4 class="mb-3">{{ index }}</h4>
          <div
            class="card mb-3"
            v-for="(pertanyaan, idx) in form"
            :key="`pertanyaan-${idx}`"
          >
            <!-- Pertanyaan Type ESSAY -->
            <div class="card-body" v-if="pertanyaan.type === 'ESSAY'">
              <h6 class="card-subtitle mb-3">
                {{ idx + 1 + ". " + pertanyaan.text }}
              </h6>
              <input
                class="form-control form-control-sm"
                id="jawabanESSAY"
                placeholder="Jawaban Anda"
                v-model="input_es[pertanyaan.id]"
                :disabled="!angket.repeatable && angket.submitted"
                :name="pertanyaan.text"
                v-validate="'required'"
                :class="{ 'is-invalid': errors.has(pertanyaan.text) }"
              />
            </div>
            <!-- Pertanyaan Type MC -->
            <div class="card-body" v-if="pertanyaan.type === 'MC'">
              <h6 class="card-subtitle mb-3">
                {{ idx + 1 + ". " + pertanyaan.text }}
              </h6>
              <div
                class="form-check"
                v-for="(mc, index) in pertanyaan.jawaban"
                :key="`mc-${index}`"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  :value="mc"
                  :id="mc.id"
                  v-model="input_mc"
                  :disabled="!angket.repeatable && angket.submitted"
                  :name="pertanyaan.text"
                  v-validate="'required'"
                  :class="{ 'is-invalid': errors.has(pertanyaan.text) }"
                />
                <label class="form-check-label" :for="mc.id">
                  {{ mc.text }}
                </label>
              </div>
            </div>
            <!-- Pertanyaan Type SC -->
            <div class="card-body" v-if="pertanyaan.type === 'SC'">
              <h6 class="card-subtitle mb-3">
                {{ idx + 1 + ". " + pertanyaan.text }}
              </h6>
              <div
                class="form-check"
                v-for="(sc, index) in pertanyaan.jawaban"
                :key="`sc-${index}`"
              >
                <input
                  class="form-check-input"
                  type="radio"
                  :id="sc.id"
                  :value="sc.id"
                  :disabled="!angket.repeatable && angket.submitted"
                  v-model="input_sc[pertanyaan.id]"
                  :name="pertanyaan.id"
                  v-validate="'required'"
                  :class="{
                    'is-invalid': errors.has(String(pertanyaan.id)),
                  }"
                />
                <label class="form-check-label" :for="sc.id">
                  {{ sc.text }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-lg-12 mt-4"
          v-if="!angket.submitted || angket.repeatable"
        >
          <button class="btn btn-primary" @click="submit">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["id_form"],
  data() {
    return {
      angket: {},
      input_mc: [],
      input_sc: [],
      input_es: [],
    };
  },

  methods: {
    getAngkets() {
      if (!this.$psb.TokenParser.user().uuid) return;
      this.$store.commit("setLoading", true);
      // this.$topprogressbar.start();
      this.$axios
        .get(
          `/pendaftar/${this.$psb.TokenParser.user().uuid}/form-angket/${
            this.id_form
          }`
        )
        .then((res) => {
          // init Pertanyaan
          const pertanyaan = {};
          for (let key in res.data.pertanyaan) {
            pertanyaan[key] = res.data.pertanyaan[key].map((item) => {
              if (item.type === "MC") {
                item.jawaban = item.jawaban.map((jwb) => {
                  jwb.id_pertanyaan = item.id;
                  return jwb;
                });
              }
              return item;
            });
          }
          this.angket = {
            ...res.data,
            pertanyaan,
          };

          // Parse jawaban yang sudah diisi
          for (let key in pertanyaan) {
            pertanyaan[key].forEach((item) => {
              if (item.type === "ESSAY") {
                this.input_es[item.id] = item.jawaban;
              } else if (item.type === "MC") {
                item.jawaban.forEach((jwb) => {
                  if (jwb.selected) {
                    this.input_mc.push(jwb);
                  }
                });
              } else if (item.type === "SC") {
                item.jawaban.forEach((jwb) => {
                  if (jwb.selected) {
                    this.input_sc[item.id] = jwb.id;
                  }
                });
              }
            });
          }

          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          this.$store.commit("setLoading", false);
        })
        .finally(() => {
          this.$store.commit("setLoading", false);
        });
    },
    submit() {
      const jawaban = [];

      // jawaban MC
      const jawabanMC = {};
      this.input_mc.forEach((item) => {
        if (jawabanMC[item.id_pertanyaan] == undefined) {
          jawabanMC[item.id_pertanyaan] = [];
        }
        jawabanMC[item.id_pertanyaan].push(item.id);
      });
      for (let id_pertanyaan in jawabanMC) {
        jawaban.push({
          id_pertanyaan: id_pertanyaan,
          type: "MC",
          id_jawaban: jawabanMC[id_pertanyaan],
        });
      }

      // jawaban SC
      for (let id_pertanyaan in this.input_sc) {
        jawaban.push({
          id_pertanyaan: id_pertanyaan,
          type: "SC",
          id_jawaban: this.input_sc[id_pertanyaan],
        });
      }

      // jawaban ESSAY
      for (let id_pertanyaan in this.input_es) {
        jawaban.push({
          id_pertanyaan: id_pertanyaan,
          type: "ESSAY",
          essay: this.input_es[id_pertanyaan],
        });
      }

      var add = () => {
        this.$store.commit("setLoading", true);
        this.$axios
          .post(
            `/pendaftar/${this.$psb.TokenParser.user().uuid}/form-angket/${
              this.id_form
            }`,
            jawaban
          )
          .then((res) => {
            this.$root.$emit("setNotif", {
              title: res.data.status,
              message: res.data.deskripsi,
              type: "success",
            });
            this.$router.push("/angket");
          })
          .catch(() => {
            this.$store.commit("setLoading", false);
          })
          .finally(() => {
            this.$store.commit("setLoading", false);
          });
      };
      this.$validator.validateAll().then((result) => {
        if (result) {
          add();
        } else {
          this.$root.$emit("setNotif", {
            title: "Ops!",
            message: `<div>Pastikan semua pertanyaan dijawab </div>`,
            type: "warning",
          });
        }
      });
    },
  },

  mounted() {
    this.getAngkets();
  },
};
</script>
